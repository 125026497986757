/*
 * @Date: 2022-03-17 14:00:20
 * @LastEditors: YuWenYun
 * @LastEditTime: 2022-03-21 14:10:55
 * @Description: 
 * @version: 
 * @Author: YuWenYun
 */
import request from '../../util/request';
const version = 'v1';

export default {
  //获取草稿内容
  getPreviewContent(data) {
    return request.get({ url: `/bff/martech-cme/draft/${version}/getDraft/${data.id}` });
  },

  // 获取名片信息
  getCardInfo(params) {
    return request.get({ url: `/bff/martech-cme/merchant/${version}/card-info`, params });
  },

  // 获取微信公众号签名信息及用户信息
  async getWxconfig(params) {
    return await request.get({ url: '/bff/api/getInfo', params, hideLoading: true });
  },

  pushLocation(data) {
    return request.post({ url: `/bff/martech-cme/auth/${version}/push-address`, data, hideLoading: true });
  },

  // 分享
  pushShare(data) {
    return request.post({ url: `/bff/martech-cme/share/${version}/up-share`, data, hideLoading: true });
  },

};
