import request from '../../util/request';
// 根据id查询视频详情

export default {
    getVideoById(data) {
        return request.get({
            url: `/bff/martech-cme/v1/video/query-video`,
            params: { videoId: data.videoId }
        });
    },
}
