<template>
  <div class="video">
    <error :errMsg="articleMsg" v-if="!isPreview && articleError" />
    <div v-else class="content" id="content">
      <div class="title-group">
        <div class="title" v-if="data && data.title">{{ data.title }}</div>
        <div class="date" v-if="data && data.updateTime">{{ data.updateTime }}</div>
      </div>
      <video ref="videoRef" :src="data.url" v-if="data.url" width="100%" height="200px;" controls></video>
      <!-- 名片 -->
      <template v-if="data && data.hasCard && cardId">
        <card :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'video'"></card>
      </template>
      <!-- 聊一聊 -->
      <template v-if="data && data.hasCard && cardId">
        <fixed-button :cardInfo="cardInfo" :paramsData="this.paramsData" :trackData="this.trackData" :contentType="'video'"></fixed-button>
      </template>
      <div class="footer">
        播放
        <span class="num m-r-16">{{ data.videoPv }}</span> 分享
        <span class="num">{{ data.shareNumber }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Common from '@/api/common';
import Video from '@/api/video';
import wxFn from '@/util/wxFn';
import trackFn from '@/util/trackFn';
export default {
  data() {
    return {
      paramsData: {}, // 存放接口参数
      data: null,
      isPreview: false,
      pageOverLay: false,
      cmeId: '',
      cardId: '',
      extField: '',
      articleError: false,
      articleMsg: '该文章已关闭',
      cardInfo: {},
      trackDataTemp: {
        watchTimes: null
      },
      counter: 0,
      timer: null,
      trackData: {}
    };
  },
  watch: {
    counter: {
      handler(val) {
        this.$set(this.trackDataTemp, 'watchTimes', val);
      },
      deep: true,
      immediate: false
    }
  },
  mounted() {
    if (this.$route.query.cmeId) {
      this.pageOverLay = true;
      if (this.$route.query.from && this.$route.query.from === 'draft') {
        this.getDraftData(this.$route.query.cmeId);
        this.isPreview = true;
      } else if (this.$route.query.from && this.$route.query.from === 'preview') {
        this.getPreviewData(this.$route.query.cmeId);
        this.isPreview = true;
      } else {
        this.getArticleData(this.$route.query.cmeId);
        this.initWx();
      }
    }
  },
  methods: {
    async getDraftData(id) {
      try {
        const { content } = await Common.getPreviewContent({ id });
        if (content.videoName) {
          document.title = content.videoName;
        }
        this.data = content;
      } catch (error) {
        console.error('error', error);
        this.$toast(error.msg || '网络错误');
      }
    },
    async getPreviewData(id) {
      try {
        const data = await Video.getVideoById({ videoId: id });
        this.data = data;
      } catch (error) {
        this.$toast(error.msg || '网络错误');
      }
    },
    async getArticleData(id) {
      try {
        this.data = await Video.getVideoById({ videoId: id });
      } catch (error) {
        if (error.code && error.code === 'C41031') {
          this.articleError = true;
          this.articleMsg = '该视频已删除';
        } else {
          this.$toast(error.msg || '网络错误');
        }
      }
    },
    async initWx() {
      try {
        const wxData = await wxFn.init(['onMenuShareTimeline', 'onMenuShareAppMessage', 'getLocation']);
        // 这里写微信的接口
        let paramsData = wxData;
        this.paramsData = wxData;
        this.cardId = paramsData.params.saleId ? paramsData.params.saleId : '';
        this.cmeId = paramsData.params.cmeId;
        this.extField = JSON.stringify(paramsData.params);
        if (this.cardId) {
          this.getInfo();
        } else {
          this.pageOverLay = false;
        }
        wxFn.initWxShareShare(paramsData, this.data.title, this.data.summary, this.data.cover, 'video', this.data);
        // 触发埋点 浏览
        this.trackData = { ...this.data, ...this.trackDataTemp };

        // 浏览视频IN（大米要求必传的事件）
        trackFn.contentViewTrace(paramsData, this.trackData, 'IN', 'video');

        // 监听开始播放
        const ele = this.$refs.videoRef;
        ele.addEventListener('play', () => {
          this.start();
          // 浏览视频IN 触发IN事件埋点
          trackFn.videoViewTrace(paramsData, this.trackData, 'IN');
        });

        // 暂停
        ele.addEventListener('pause', () => {
          this.stop();
        });

        // 结束
        ele.addEventListener(
          'ended',
          () => {
            this.$set(this.trackData, 'watchTimes', this.counter);
            // 浏览视频IN 触发IN事件埋点
            trackFn.videoViewTrace(paramsData, this.trackData, 'OUT');
          },
          false
        );

        // 退出
        // trackFn.contentOutViewTrace(paramsData, this.trackData, 'video');
      } catch (error) {
        console.error('error', error);
        this.$toast(error.message || '网络错误');
      }
    },
    // 获取名片信息
    async getInfo() {
      try {
        const params = {
          cardId: this.cardId,
          cmeId: this.cmeId,
          extField: this.extField
        };
        this.cardInfo = await Common.getCardInfo(params);
        this.$store.commit('app/SET_CARD_DATA', this.cardInfo);
        this.pageOverLay = false;
      } catch (error) {
        this.$toast(error.msg || '请求异常');
      }
    },
    // 开始计时
    start() {
      this.time = setInterval(() => {
        this.counter++;
      }, 1000);
    },
    // 结束计时
    stop() {
      clearInterval(this.time);
    }
  },
  destroyed() {
    clearInterval(this.time); // 清除定时器
    this.time = null;
    this.counter = 0;
  }
};
</script>

<style lang="less" scoped>
.video {
  overflow: auto;
  height: 100vh;
  .content {
    padding: 26px 20px;
    box-sizing: border-box;
  }
  .title-group {
    margin-bottom: 16px;
  }
  .title {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
  }
  .date {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 400;
    font-size: 12px;
  }
  .footer {
    margin-top: 24px;
    font-size: 12px;
    color: #999999;
    .num {
      color: #4d78ff;
    }
    .m-r-16 {
      margin-right: 16px;
    }
  }
}
</style>
