import Common from '@/api/common';
const qs = require('querystring');
import md5 from 'js-md5';
import trackFn from '@/util/trackFn';
export default {
  /* 初始化wxjsdk各种接口 */
  async init(apiList = [], cmeId) {
    try {
      //需要使用的api列表
      const hostname = window.location.href;
      const paramsData = await Common.getWxconfig({ url: hostname, cmeId });
      sessionStorage.setItem('USER-PARAMS-DATA', JSON.stringify(paramsData));
      if (paramsData.wxSign.appId) {
        wx.config({
          appId: paramsData.wxSign.appId,
          timestamp: paramsData.wxSign.timestamp,
          nonceStr: paramsData.wxSign.nonceStr,
          signature: paramsData.wxSign.signature,
          jsApiList: apiList,
          openTagList: ['wx-open-launch-weapp']
        });
        if (paramsData.hasOwnProperty('params') && paramsData.hasOwnProperty('user')) {
          let resultObj = (await this.getData(paramsData)) || '';
          await trackFn.initTrace(paramsData, resultObj); // 改为传参数的方式 ，防止手机上sessionStorage第一次丢失
          return paramsData;
        } else {
          return paramsData;
        }
      }
    } catch (error) {
      console.log(error, 'Error');
    }
  },
  async initWxShareData(data, shareParams, isAlreadyShare) {
    // 如果是分享成功后调用，则重新生成shareId
    if (isAlreadyShare) {
      shareParams.shardId = md5(data.user.openId + Date.now());
    }
    const url = `${data.baseConfig.sharePath}?${qs.stringify(shareParams)}`;
    // 分享数据：
    const shareData = {
      shardId: shareParams.shareId, // 分享id: MD5 openId + 时间戳
      entityId: shareParams.cmeId, // 内容Id
      entityType: 1, // 类型 1内容 2活动
      shareOpenId: data.user.openId, // 分享人ID
      shareUserName: data.user.nickName, // 分享人
      shareUrl: url, // 分享的地址
      extField: JSON.stringify(shareParams), // 扩展字段
      shareTraceId: shareParams.shareTraceId
    };
    return shareData;
  },
  initWxShareShare(data, title, desc, img, type, info) {
    let defaultDesc;
    let shareFuncType; // 分享的埋点类型： 表单，文章，h5, 活动，值需要与trackFn内具体调用的方法名一致
    switch (type) {
      case 'form':
        defaultDesc = '表单描述';
        shareFuncType = 'formShareTrace';
        break;
      case 'article':
        defaultDesc = '文章描述';
        shareFuncType = 'articleShareTrace';
        break;
      case 'h5':
        defaultDesc = 'H5描述';
        shareFuncType = 'h5ShareTrace';
        break;
      case 'activity':
        defaultDesc = '活动描述';
        shareFuncType = 'activityShareTrace';
        break;
      case 'video':
        defaultDesc = '视频描述';
        shareFuncType = 'videoShareTrace';
        break;
      default:
        defaultDesc = '';
    }
    const params = data.params;
    // 拷贝参数，为了删除里面的url
    const shareParams = { ...params };
    // 删除分享连接中的url;
    delete shareParams.url;
    const md5ShareId = md5(data.user.openId + Date.now());
    const sharedPeopleId = data.user.unionId;
    let md5TraceId = md5(data.user.openId + Date.now() + '1');

    // 如果参数中有shareTraceId,则从参数中取，如果没有则新生成
    if (shareParams.shareTraceId) {
      md5TraceId = shareParams.shareTraceId;
    }
    shareParams.shareId = md5ShareId;
    shareParams.shareTraceId = md5TraceId;
    shareParams.sharedPeopleId = sharedPeopleId;
    // 如果参数中有depth, depth+1,如果没有则depth为1
    shareParams.depth = shareParams.depth ? parseInt(shareParams.depth) + 1 : 1;

    wx.ready(async () => {
      const shareData = await this.initWxShareData(data, shareParams);
      // 分享到朋友圈
      wx.onMenuShareTimeline({
        title: title, // 分享标题
        desc: desc ? desc : defaultDesc, // 分享描述
        link: shareData.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img ? img : '', // 分享图标
        success: async () => {
          try {
            // 上报要放前面，要不然会location.reload会先执行，上报不了分享数据
            // trackFn[shareFuncType](data, info, 'MOMENTS'); // 埋点 分享触发(shareFuncType为trackFn.js内具体调用的分享函数名)
            trackFn.contentShareTrace(data, info, 'MOMENTS', type);
            await Common.pushShare(shareData); // 推送地理位置
          } catch (e) {
            console.log(e.message);
          }
          this.initWxShareData(data, shareData, true);
        }
      });
      // 发送给微信好友
      wx.onMenuShareAppMessage({
        debug: true,
        title: title, // 分享标题
        desc: desc ? desc : defaultDesc, // 分享描述
        link: shareData.shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: img ? img : '', // 分享图标
        success: async () => {
          // 成功
          try {
            // 放这里面，不要影响主流程
            // 上报要放前面，要不然会location.reload会先执行，上报不了分享数据
            // trackFn[shareFuncType](data, info, 'FRIEND'); // 埋点 分享触发(shareFuncType为trackFn.js内具体调用的分享函数名)
            trackFn.contentShareTrace(data, info, 'FRIEND', type);
            await Common.pushShare(shareData); // 推送地理位置
          } catch (e) {
            console.log(e.message);
          }
          this.initWxShareData(data, shareData, true);
          // location.reload(); // 刷新页面(解决url内shareId相同的问题)
        }
      });
    });
  },

  getData(paramsData) {
    let networkType = null;
    let locationObj;
    return new Promise((resolve, reject) => {
      try {
        wx.ready(async () => {
          console.log('getData');
          // 微信SDK准备就绪后执行的回调（接口数据）。
          // 这个判断主要是方便h5预览的情况下使用
          try {
            locationObj = await this.pushAddress(paramsData);
            networkType = await this.getNetworkInfo();
            let obj = {
              wifi: networkType,
              ...locationObj
            };
            resolve(obj);
          } catch (error) {
            console.log('pushAddress', error);
          }
        });
        wx.error(function (res) {
          console.error('wx error', res);
          resolve(null);
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      } catch (error) {
        resolve(null);
      }
    });
  },
  // 设备信息: 获取网络状态接口
  getNetworkInfo() {
    return new Promise(function (resolve, reject) {
      try {
        wx.getNetworkType({
          success: res => {
            resolve(res.networkType);
            sessionStorage.setItem('NET_WORK_TYPE', res.networkType);
          }
        });
      } catch (error) {
        resolve(null);
      }
    });
  },
  pushAddress(paramsData) {
    return new Promise(function (resolve, reject) {
      try {
        // 地理位置
        wx.getLocation({
          type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: res => {
            let data = {
              appId: paramsData.params.appid || '',
              cmeId: paramsData.params.cmeId,
              unionId: paramsData.user.unionId,
              openId: paramsData.user.openId,
              latitude: res.latitude ? res.latitude + '' : '', // 纬度，浮点数，范围为90 ~ -90
              longitude: res.longitude ? res.longitude + '' : '', // 经度，浮点数，范围为180 ~ -180。
              speed: res.speed ? res.speed + '' : '', // 速度，以米/每秒计
              accuracy: res.accuracy ? res.accuracy + '' : '' // 位置精度
            };
            let locationObj = Common.pushLocation(data);
            resolve(locationObj);
          },
          cancel: res => {
            console.log('用户拒绝授权获取地理位置' || res);
            let locationObj = {
              city: null,
              province: null
            };
            resolve(locationObj);
          },
          fail: error => {
            console.log('用户权获取地理位置error' || error);
            let locationObj = {
              city: null,
              province: null
            };
            resolve(locationObj);
          },
          complete: () => {
            console.log('用户权获取地理位置complete');
          }
        });
      } catch (error) {
        console.log('getLocation error', error);
        resolve(null);
      }
    });
  }
};
